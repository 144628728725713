import React from "react";
import brand from "@utils/brand";
import { BlogTease, HeadingWIcon } from "@molecules";
import { Container, AnimateIn, ButtonAlt } from "@atoms";

const BlogSection = ({ articles }) => {
  const { romero } = brand;
  return (
    <div className="bg-cream py-12 sm:py-24">
      <Container>
        <div className="flex flex-wrap items-start md:flex-nowrap">
          <HeadingWIcon
            heading="Blog"
            copy="Read the latest news and updates from our active programs."
            colors={{ heading: "text-blue" }}
            className="font-serif"
          />
          <ButtonAlt
            to="/blog"
            color="gold"
            reverse={romero}
            className="ml-0 md:ml-auto"
          >
            View All Articles
          </ButtonAlt>
        </div>
        <div className="mt-12 flex flex-wrap space-x-0 space-y-12 md:mt-6 md:flex-nowrap md:space-x-6 md:space-y-0">
          <div className="w-full md:w-2/3">
            <AnimateIn>
              <BlogTease stack big showExcerpt {...articles?.[0]} />
            </AnimateIn>
          </div>
          <div className="mt-6 flex w-full flex-shrink-0 flex-col space-y-12 md:mt-0 md:w-1/3 md:space-y-6">
            <AnimateIn>
              <BlogTease stack {...articles?.[1]} />
            </AnimateIn>
            <AnimateIn>
              <BlogTease stack {...articles?.[2]} />
            </AnimateIn>
          </div>
        </div>
      </Container>
    </div>
  );
};

BlogSection.defaultProps = {};

export default BlogSection;
